/* global define, siteObj */
/* jshint forin: false, unused: false, curly: false */

define(['jquery', 'productDetails'], function($, ProductDetails) {

  /*
  * Presenter: An adaptive presenter for the data on the Product Details page that works with markup is sent from the server
  * @constructor
  */
  $(function() {

    // check we have a product Id before doing anything further
    var productId = siteObj.productID;
    if (!productId) return;

    // find the server side variations panel
    var $existing = $('.productoptions').eq(0);
    if (!$existing.length) return;

    adaptiveVariations($existing, productId);

  });

  /*
   * View: An adaptive html view representing the data on the Product Details page.
   * @constructor
   */
  function AdaptiveVariationsUI($el) {
    this.$el = $el;
    this.$quantity = $el.find('input[name=quantity]').eq(0);
    this.$price = $el.find('.price').eq(0);
    this.$priceRRP = $el.find('.price-rrp').eq(0);
    this.$savingAmount = $el.find('.saving-amount').eq(0);
    this.$savingPercent = $el.find('.saving-percent').eq(0);
    this.$variations = $el.find('select[name=option]');
    this.$buyNow = $el.find('.buying-area button[type=submit]');
  }
  AdaptiveVariationsUI.prototype = {
    render: function(productDetails, updatePrices) {

      if (typeof updatePrices === 'undefined') {
        updatePrices = true;
      }

      var details = productDetails.details();
      var selections = productDetails.selections();
      var variations = productDetails.variations();
      var selectedChildProduct = productDetails.selectedChildProduct();

      // update the quantity
      this.$quantity.val(productDetails.quantity());

      // update the prices
      if (updatePrices) {
        this.$price.html(productDetails.price());
        this.$priceRRP.html(productDetails.priceRRP());
        this.$savingAmount.html(productDetails.savingAmount());
        this.$savingPercent.html(productDetails.savingPercent());
      }

      // update the disabilty of each of the variations options
      this.$variations.each(function(index, element) {

        var $variation = $(this);
        var variationId = $variation.attr('id').replace('opts-', '');
        var $options = $variation.find('option');

        // reset all options
        $options.removeAttr('disabled');

        $options.each(function(index, element) {
          var $option = $(this);
          var optionId = $option.val();
          if (optionId) {

            // these two lines make the swatch colour pickers work
            var optionValue = variations[variationId].options[optionId].value;
            $option.attr('rel', optionValue);

            if (!productDetails.isOptionEnabled(variationId, optionId)) {
              $option.attr('disabled', 'disabled');
            }
          }
        });

      });

      // update BulkBuy for te selected child product
      if (window.updateBulkBuy && selectedChildProduct) {
        if (selectedChildProduct.bulkBuy) {
          window.updateBulkBuy(selectedChildProduct.bulkBuy);
        }
      }

      // redraws the colour picker
      if (window.colourPicker) {
        window.colourPicker();
        if (selectedChildProduct) {
          this.replaceMainImage(selectedChildProduct, details.images);
        }
      }

      // update the buynow button disability
      if (productDetails.canAddToBasket()) {
        this.$buyNow
          .removeAttr('disabled')
          .removeClass('disabled');
      } else {
        this.$buyNow
          //.attr('disabled', 'disabled')
          .addClass('disabled');
      }
    },
    replaceSingleOptionSelects: function() {
      this.$variations.each(function(index, element) {
        var $this = $(this);
        var $options = $this.find('option[value!=""]');
        if ($options.length === 1) {
          var text = $options.eq(0).text();
          var position = $this.position();
          var width = $this.outerWidth() + 'px';
          var height = $this.outerHeight() + 'px';
          var $label = $('<span>');

          // style the label so it's positioned over and
          // sized the same as the select element it's replacing
          $label
            .html(text)
            .css({
              width: width,
              height: height,
              lineHeight: height,
              top: position.top,
              left: position.left,
              position: 'absolute'
            });

          // insert the replacing label before the select and
          // then make the select element invisible
          // (we want it to retain it's space to keep the layout the same)
          $this
            .before($label)
            .css({
              visibility: 'hidden'
            });
        }
      });

    },
    selections: function() {
      var selections = (Object.create ? Object.create(null) : {});

      this.$variations.each(function(index, element) {
        var $variation = $(this);
        var variationId = $variation.attr('id').replace('opts-', '');
        var val = $variation.val();
        if (val) {
          selections[variationId] = $variation.val();
        }
      });

      return selections;
    },
    enableAllVariations: function() {
      this.$variations
        .removeAttr('disabled')
        .removeClass('disabled');
    },
    replaceMainImage: function(selectedChildProduct, productImages) {
      // todo: this has been copied very much as-was. It needs tidying up....

      var $img = $('.product-area .product-image .product-img'),
        images = selectedChildProduct.images,
        imageName;

      if ($img.length && images && images.length) {

        // set image name to either extra large or largeproduct
        for (var i = 0; i <= images.length; i++) {

          if (productImages[images[i]].type === 'extralarge') {
            imageName = productImages[images[i]].url;
            break;
          }
          if (images[i].type === 'largeproduct') {
            imageName = productImages[images[i]].url;
            break;
          }
        }

        // if extralarge or largeproduct don't exist just use the last image - protection against the script just failing
        if (typeof imageName === 'undefined') {
          imageName = productImages[images.length].url;
        }

        var imgSrc = $img.attr('src'),
          resultImgSrc = imgSrc.match(/https?:\/\/[^\/]*\//) + imageName,
          resultImgThumbSrc = imgSrc.match(/https?:\/\/[^\/]*\//) + images[0].url;

        // replace image
        if (imageName.length > 0 && '"' + imgSrc.match(/[^\/]*$/) + '"' !== '"' + resultImgSrc.match(/[^\/]*$/) + '"') {

          if ($('.field option:nth-child(1):selected').length === 0) {
            $img.attr('src', resultImgSrc).load(function(e) {
              if (window.productImageDidAppear)
                window.productImageDidAppear();
            });
          }
        }
      }
    }
  };

  /* exports */
  function adaptiveVariations($existing, productId) {

    // check we have a product Id brfore doing anything further
    if (!productId) return;

    var url = siteObj.siteURL + 'allvariations.json?productId=' + productId;

    $
      .getJSON(url)
      .done(function(data, textStatus, jqXHR) {

        if (data && data.product) {

          // ***************************************
          // Instantiate a new ProductDetails object
          // ***************************************
          var productDetails = new ProductDetails({
            details: data
          });

          // preselect any single option variations
          productDetails.preSelectSingleOptions();

          // ********************************************
          // Instantiate a new DefaultVariationsUI object
          // ********************************************
          var ui = new AdaptiveVariationsUI($existing, productDetails);

          // default selection values from initial DOM
          productDetails.select(ui.selections());

          // ************************
          // Attach ui event handlers
          // ************************

          // onchange quantity, update the ProductDetails model
          ui.$el.on('change', 'input[name=quantity]', function(e) {
            var quantity = parseInt($(this).val());
            if (!isNaN(quantity)) {
              productDetails.quantity(quantity);
            }
          });

          // onchange any select element, update/clear the ProductDetails selections
          ui.$el.on('change', 'select', function(e) {
            var $this = $(this);
            var variationId = $this.attr('id').replace('opts-', '');
            var selectedOptionId = $this.val();
            if (selectedOptionId) {
              productDetails.select(variationId, selectedOptionId);
            } else {
              productDetails.clearSelection(variationId);
            }
          });

          // The main buy now button is never actually disabled. It's only made to look disabled.
          // Let's handle the click event here and protect against adding invalid items to the basket.
          ui.$buyNow.on('click', function(e) {
            if (productDetails.canAddToBasket()) return;

            e.preventDefault();
            alert(siteObj.props.ajaxBasket.errors.selectAnOption); // alert user if no product selected
            return false;
          });

          // onsubmit form, call the common basket.init_add function
          // This will only fire if the above handler ui.$buyNow.on('click')
          // has allowed it's event to propagate
          ui.$el.on('submit', 'form', function(e) {
            e.preventDefault();

            var $form = $(this);
            var productId = productDetails.details().product.id;
            var quantity = productDetails.quantity();
            var $button = $form.find('button[type=submit]').eq(0);
            var variations = productDetails.getSelectedVariationsPostData();

            siteObj.fn.basket.init_add(productId, quantity, $button, variations);

            var btext = $.trim($button.text()), // get the button text so we can differentiate betweeen Buy Now and Pre-Order
              pagetype = $(document.body).attr('class').split(' ', 1); // split the body class incase it contains more than one

            if (pagetype === '') {
              pagetype = 'No Dept';
            }
            if (btext === '') {
              btext = 'Misc';
            }
            siteObj.fn.log(['_trackEvent', pagetype[0], 'Buying', btext]);

            return false;
          });

          // Attach model event handlers
          productDetails.on(ProductDetails.prototype.events.SELECTION_CHANGED, function(selections) {
            ui.render(this);
          });

          // Enable all variation elements.
          // Mobile cascaded variations are disabled on page load.
          ui.enableAllVariations();

          // replace selects with labels if
          // they only have a single option
          ui.replaceSingleOptionSelects();

          // render (adapt) the ui.
          // On the initial page load we do not want to update the prices.
          ui.render(productDetails, false);

          // initialize colour picker
          if (window.colourPicker) {
            window.colourPicker();
          }

        }

      })
      .fail(function(data, textStatus, jqXHR) {

        console.log('Error retrieving product variations data', textStatus);

      });
  }

  window.variations = adaptiveVariations; // refactor, currently required for quick-view...

  return adaptiveVariations;
});
